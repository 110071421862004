import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { GiphyModalComponent } from '../giphy-modal/giphy-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [GiphyModalComponent],
  exports: [GiphyModalComponent],
})
export class GiphyModalModule {}
