import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { CustomFieldsComponent } from './custom-fields.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, NgxTippyModule],
  declarations: [CustomFieldsComponent],
  exports: [CustomFieldsComponent],
})
export class CustomFieldsModule {}
