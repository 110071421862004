import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() size = 120;

  private fallbackUrl: string;

  constructor() {}

  getImgSource() {
    return this.image || this.getFallbackAvatar();
  }

  handleError() {
    this.image = this.getFallbackAvatar();
  }

  private getFallbackAvatar() {
    return `https://api.dicebear.com/9.x/shapes/svg?seed=${this.name}&size=${this.size}`;
  }
}
