import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DatetimePickerComponent } from './datetime-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [DatetimePickerComponent],
  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
