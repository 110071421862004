import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { ResponseInputComponent } from './response-input.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, NgxTippyModule],
  declarations: [ResponseInputComponent],
  exports: [ResponseInputComponent],
})
export class ResponseInputModule {}
